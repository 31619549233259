import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import Section from '../components/Common/Section'
import Container from '../components/Common/Container'
import Typography from '../components/Common/Typography'
import Link from '../components/Common/Link'
import { Button } from '../components/Common/Button'
import RequestDemo from '../components/RequestDemo'
import ContactForm from '../components/contact-form'
import { useTranslations } from '../hooks/use-translations'

import * as s from '../pages/news.module.scss'

const OperationalPhase = () => {
  const { t, getPath } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)
  return (
    <>
      <SEO
        title={t('seo_title_operational_phase')}
        description="In the project's operational stage, the focus shifts to the construction project's ongoing maintenance and operation. Digital twins are employed to replicate physical assets virtually, allowing for real-time monitoring and analysis. This enables predictive maintenance, where potential issues can be identified and addressed before they lead to costly disruptions, ensuring optimal performance."
      />
      <Section>
        <Container>
          <Typography variant="body1" color="gray" style={{ marginBottom: 8 }}>
            {t('date_operational_phase')}
          </Typography>
          <Typography variant="h1" color="blue" size={40} mb={48}>
            {t('title_operational_phase')}
          </Typography>
          <Typography variant="body1" size={18}>
            {t('operational_phase_text')}
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('operational_phase_subtitle')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('operational_phase_text1.1')}{' '}
                <Link
                  href="https://bimforum.org/resource/lod-level-of-development-lod-specification"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  {t('operational_phase_text1.2')}
                </Link>{' '}
                {t('operational_phase_text1.3')}
              </Typography>
            </div>
            <div className="flex flex-col">
              <StaticImage
                width={564}
                className="mb-8"
                src="../assets/images/solutions/operational-phase/image-1.png"
                alt="Digital Twin Development"
                title="Digital Twin Development"
                placeholder="blurred"
              />
              <StaticImage
                width={564}
                src="../assets/images/solutions/operational-phase/image-2.png"
                alt="Digital Twin Development 2"
                title="Digital Twin Development 2"
                placeholder="blurred"
              />
            </div>
          </div>
          <Typography
            variant="h2"
            size={24}
            className={s.margin_top_48}
            mb={16}
          >
            {t('operational_phase_subtitle2')}
          </Typography>
          <Typography variant="body1" size={18}>
            {t('operational_phase_text2')}
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('operational_phase_subtitle3')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('operational_phase_text3')}
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/operational-phase/image-3.png"
                alt="Virtual Training"
                title="Virtual Training"
                placeholder="blurred"
              />
            </div>
          </div>
          <Button
            size="large"
            className={cn('mx-auto', s.margin_top_48)}
            onClick={() => navigate(getPath('/bim-services/'))}
          >
            {t('Other BIM services')}
          </Button>
        </Container>
      </Section>
      <RequestDemo
        title={t('Know more')}
        bgImage={images.demoBg.childImageSharp.gatsbyImageData}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default OperationalPhase

const imagesQuery = graphql`
  query {
    demoBg: file(relativePath: { eq: "services-solutions-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
